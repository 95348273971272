import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/escerlogo.png";


const WorkInProgress = () => {
    return (
        <React.Fragment>
            <div className="header-title">Escher Ensemble</div>
            <a href="mailto:info@escherensemble.nl?subject=Info Escher Ensemble"
               className="header-logo">
                e-mail Escher Ensemble
            </a>
            <div className="header-logo"><Link to="/about"><img src={logo} alt="escherlogo"/></Link></div>
        </React.Fragment>
    );
};

export default WorkInProgress;
