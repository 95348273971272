import React from "react";
import {Link} from "react-router-dom";
import logo from "../images/escerlogo.png";
import AboutEnglishText from './AboutEnglishText';
import AboutDutchText from './AboutDutchText';

export default class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {showEnglish: false};
    }

    switchLanguage (e) {
        e.preventDefault();
        this.setState({showEnglish: !this.state.showEnglish})
    }


    render() {
        return (
            <React.Fragment>
                <div className="header-logo-small"><Link to="/"><img src={logo} alt="escherlogo"/></Link></div>
                <div className="language-switch">
                    <button
                        className="ui grey basic button"
                        onClick={(e)=>this.switchLanguage(e)}
                    >
                        <i className="world icon"></i>
                        {this.state.showEnglish ? 'Dutch' : 'English'}
                    </button>
                </div>
                <div className="context-text">
                    {this.state.showEnglish ? <AboutEnglishText />:<AboutDutchText />}
                </div>
            </React.Fragment>
        );

    }
}