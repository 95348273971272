import '../css/style.css';
import React, {Component} from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';

import WorkInProgress from "./WorkInProgress";
import About from "./About";


class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Route exact path="/" component={WorkInProgress} />
                    <Route path="/about" component={About} />
                </div>
            </Router>
        );
    }
}

export default App;
