import React from "react";

function AboutEnglishText() {
    return (
        <p>The Escher Ensemble has been founded in 1993 by Tom Bakker and Hubert-Jan Hubeek. They wanted to perform the
            2<sup>nd</sup> pianoconcert by Rachmaninov and the Rhapsody in Blue by George Gershwin under the old oak
            tree in the Vondelpark in Amsterdam. They wrote arrangements for a string quintet and a saxophone quintet
            and the piano solist was Frank Mol.<br/>
            The saxophone quintet was formed by adding an extra saxophone player to the Escher Saxophone Quartet in
            which Hubert-Jan was performing. Hence the name 'Escher Ensemble'.<br/>
            <br/>
            Following this beginning, the Escher Ensemble became a special ensemble in the field of Dutch ensembles,
            especially by the energy and work of Rolf Buijs and Margie Kirschenmann. It presented itself with unheard
            arrangements of popular classical repetoire.<br/>
            In 1995 the Escher Ensemble won the prestigious prize of the Dutch Impressiaat. The jury mentioned the
            modern and new character of the performances. During the following tour they recorded the
            CD <italic-font-style>Escher Ensemble Live</italic-font-style>.<br/>
            <br/>
            Frank Mol composed the opera <italic-font-style>Gaslicht</italic-font-style> for the Escher Ensemble and
            five solists in 1999. This opera had a succesfull tour in Holland that season.<br/>
            <br/>
            In 2011 Hubert-Jan Hubeek and cellist Jur de Vries renewed the ensemble by a new tour with performances in
            which lights and music became connected. They created an enviroment in which Mathilde Castro could perform
            her babyconcerts. These concerts had their premiere at the Festival Klassiek in the Hague.<br/>
            <br/>
        </p>
    );

}

export default AboutEnglishText;