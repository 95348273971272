import React from "react";

function AboutDutchText() {
    return (
        <p>
            Het Escher Ensemble werd in 1993 opgericht door Tom Bakker en Hubert-Jan Hubeek. Zij wilden het
            2<sup>e</sup> pianoconcert van Rachmaninov en <italic-font-style>Rhapsody in Blue</italic-font-style> van
            Gershwin uitvoeren onder
            de boom voor het oude filmmuseum in het Vondelpark. Aldus geschiedde. Ze arrangeerden de twee
            stukken voor een strijkkwintet en een saxofoonkwintet; pianist Frank Mol was solist.<br/>

            Het saxofoonkwintet voor dit concert ontstond door een extra saxofonist toe te voegen aan het al
            bestaande Escher Saxofoon Kwartet, waar Hubert-Jan in speelde. Vandaar de naam ‘Escher
            Ensemble’.<br/>
            <br/>
            Na dit spetterende begin groeide het Escher Ensemble, mede dankzij dirigent Rolf Buijs en
            saxofonist Margie Kirschenmann, uit tot een spraakmakend ensemble. Het slaagde erin om de
            geplaveide wegen van de ensemblepodia onveilig te maken met gedurfde arrangementen en
            eigenzinnig repertoire.<br/>
            <br/>

            In 1995 won het Escher Ensemble een concours van het Nederlands Impresseriaat. De jury prees het
            uitdagende, vernieuwende en toegankelijke karakter van het ensemble. Tijdens de concertreeks die
            aan deze prijs verbonden was, werden in het Concertgebouw te Amsterdam live opnames gemaakt. Dit
            resulteerde in de CD <italic-font-style>Escher Ensemble Live</italic-font-style>.<br/>
            <br/>

            Frank Mol componeerde in 1999 de opera <italic-font-style>Gaslicht</italic-font-style> voor het Escher
            Ensemble en vijf
            solisten, waaronder sopraan Irene Maessen. Deze horror-suspense opera trok volle theaterzalen in
            Nederland.<br/>
            <br/>
            In 2011 bliezen Hubert-Jan Hubeek en cellist Jur de Vries het Esher Ensemble nieuw leven in. Zij
            ontwikkelden een installatie waarbij lampen reageren op muziek. Het Escher Ensemble, nog altijd
            samengesteld uit blazers en strijkers, creëerde met deze installatie een omgeving ter
            ondersteuning van Mathilde Castro’s babyconcerten.
            Deze concerten voor de allerkleinsten gingen in première op Festival Klassiek en tourden
            vervolgens langs de concertzalen van Nederland.
        </p>
    );

};

export default AboutDutchText;